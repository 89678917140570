<template lang="html">

  <section class="novelties-hemeroteca">

    <hero-global img="/img/hero/actualidad.svg" title="Actualidad" subtitle="Actualidad" />
    <section id="novedades" class="container">
      <div class="column-center">
        <h2 class="title-grand title__double-center" data-text="Novedades CERMI">Novedades</h2>
      </div>
      <section class="tool-search">
        <form class="search-form">
          <div class="column keyword">
            <label for="keyword">Buscar por palabra</label>
            <input name="keyword" class="input-styled keyword" placeholder="Introduce tu palabra" title="Introduce tu palabra" v-model="data.keyword" />
          </div>
          <div class="column middle-width">
            <label for="dateStart">Fecha desde</label>
            <Datepicker placeholder="Selecciona la fecha" v-model="data.dateStart" name="dateStart" locale="es" autoApply :enableTimePicker="false" >
            </Datepicker>
          </div>
          <div class="column middle-width">
            <label for="dateEnd">Fecha hasta</label>
            <Datepicker placeholder="Selecciona la fecha" v-model="data.dateEnd" name="dateEnd" locale="es" autoApply :enableTimePicker="false">
            </Datepicker>
          </div>
          <div class="cont-btn">
            <div class="btn-blue" @click="sendFilters">Buscar</div>
            <div class="btn-white" @click="cleanFilters">Limpiar</div>
          </div>
        </form>
      </section>
      <template v-if="loading">
        <div class="result-box container">
          <p class="subtitle">Cargando...</p>
        </div>
      </template>
      
      <template v-else>
        <section class="grid-3" v-if="store.getNovelties(this.page)">
          <template v-for="(novelt, index) in store.getNovelties(this.page)" :key="index">
            <noveltie :dataContent="novelt" ></noveltie>
          </template>
        </section>
        <template v-else>
          <div class="result-box container">
            <p class="subtitle">Lo sentimos no hay resultados</p>
          </div>
        </template>
      </template>
      <paginator class="m-bottom-5-rem" v-if="store.getNovelties(this.page)" :pageActual="this.page" :totalItems="NoveltiesMaxItems" @clicked="changePages" @changed="changePages" />
    </section>

    <other-links-interest class="links-interest-box container" />
  </section>

</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import Paginator from "@/components/Global/PaginationGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import noveltie from "@/components/Card/Noveltie";
  import { contentStore } from '@/stores/contents'
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  const store = contentStore();
  export default {
    name: 'hemeroteca',
    props: ['idDay'],
    components: {
      OtherLinksInterest,
      HeroGlobal,
      noveltie,
      Paginator,
      Datepicker,
    },
    setup() {
      return {
        store
      }
    },
    mounted() {
      this.store.loadNovelties(
        {
          page: this.page,
          maxYear: this.yearSelected,
        }
      )
    },
    data() {
      return {
        fullPage: false,
        data: {
          keyword: this.text,
          dateStart: null,
          dateEnd: null
        }
      }

    },
    methods: {
      sendFilters() {
        this.store.loadNovelties({
          page: this.page,
          filtersResults: {
            'textFilter': this.data.keyword,
            'dataStart': this.data.dateStart,
            'dataEnd': this.data.dateEnd
          }
        })
      },
      cleanFilters() {
        this.data = {
          'keyword': '',
          'dateStart': null,
          'dateEnd': null
        }
        this.store.loadNovelties()

      },

      changePages(item, page) {
        this.store.setPagesNovelties(item)
        this.store.loadNovelties(
          {
            maxYear: this.yearSelected,
            page: this.page,
            filtersResults: {
              'textFilter': this.data.keyword,
              'dataStart': this.data.dateStart,
              'dataEnd': this.data.dateEnd
            }
          }
        )
        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: 0,
          });
        }, 200);
      },
      setYear(year) {
        this.year = year
        this.page = 1
        this.store.novelties.lastFilters.date = year
        this.store.setPagesNovelties(1)
        this.store.loadNovelties(
          {
            maxYear: this.year,
            page: 1,
          }
        )

      }


    },
    computed: {
      NoveltiesMaxItems() {
        return this.store.getPagesNovelties(this.page);
      },
      page() {
        return this.store.getNoveltiesPage
      },
      loading() {
        return this.store.loading
      },
      yearSelected() {
        let year = new Date().getFullYear()
        if (this.store.novelties.lastFilters.date) {
          year = this.store.novelties.lastFilters.date
        }
        return year
      }
    },
    watch: {
      "loading"() {
        if (this.loading) {
          let loader = this.$loading.show({
            container: this.$refs.loadingContainer,
            zIndex: 99,
          });
          setTimeout(() => {
            loader.hide()
          }, 1000)
        }
      }
    }
  }


</script>

<style scoped lang="scss">
  @import "@/styles/general.scss";

  .novelties-hemeroteca {
    .title-grand {
      margin-bottom: 25px;
      color: #8baddc5e;
    }

    .result-box {
      min-height: 38vh;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .subtitle {
        font-size: 30px;
        border-bottom: 1px solid;
      }
    }
  }
</style>